<template>
  <main class="mt-0">
    <main class="mt-0 main-content-bg">
      <section>
        <div class="page-header min-vh-100">
          <div class="main-container">
            <div class="d-flex flex-wrap justify-content-center">
              <div
                  class="" style="min-width: 400px;"
              >

                <CaliLogin class="cali-login-dif" :options="{
                    magicLink: { enabled: true, url: '/api/v1/settings/magic-link-login'},
                    password: { enabled: true, url: '/api/v1/settings/login-password' },
                    headers: {
                      'x-api-key': '9c6334a7-8215-46ff-8ec9-f442cdc43cac'
                    }
                  }"
                   @onSubmit="onSubmit"
                   @onError="onError"/>

              </div>
              <div class="col-md-4 d-md-block d-none">
                <div class="top-0 oblique position-absolute h-100 me-n8">
                  <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                      style="background: rgb(25,186,197);background: linear-gradient(143deg, rgba(25,186,197,1) 0%, rgba(84,117,176,1) 39%, rgba(162,26,150,1) 100%);"
                  ></div>
                </div>
              </div>
            </div>
<!--            <img src="https://tba.mints.cloud/public-assets/logo-mints-blanco" alt="" style="z-index: 3;position: absolute;max-width: 200px;opacity: .3;right: 30px;bottom: 30px;">-->
          </div>
        </div>
      </section>
    </main>
  </main>
</template>

<script>
import CaliLogin from '@mints-components/cali-login/App.vue';

export default {
  components: { CaliLogin },
  methods: {
    onSubmit(response) {
      let title = `Correo enviado `
      let text = `Si el correo es correcto, en un instante te llegará un correo de acceso a ${response?.credentials?.email}`

      if (response.type === 'password') {
        title = '¡Autenticación correcta!'
        text = 'Estamos iniciando sesión, espera un momento…'
      }

      this.$swal.fire({ icon: 'success', title, text })

      if (response.type === 'password') {
        setTimeout(() => {
          window.location.href = '/settings'
        }, 1250)
      }
    },
    onError({ error, type, credentials }) {
      let title = `Correo enviado `
      let text = `Si el correo es correcto, en un instante te llegará un correo de acceso a ${credentials?.email}`
      let icon = 'success'

      if (type === 'password') {
        title = 'Oops...'
        text = 'Credenciales inválidas.'
        icon = 'error'
      }

      this.$swal.fire({ icon, title, text })
    }
  }
};
</script>

<style lang="scss">

.main-container {
    margin-inline: auto;
    padding-inline: clamp(15px, var(--main-grid-padding), 70px);
    width: 100%;
    max-width: var(--main-grid-max-width);
}

.ignore-main-container {
    margin-inline: calc(-1 * clamp(15px, var(--main-grid-padding), 70px));
    display: block;
}
.main-container__only-padding {
    margin-inline: auto;
    padding-inline: clamp(15px, var(--main-grid-padding), 70px);
    width: 100%;
}
.cali-login-dif{
  padding: 0 !important;
  *{
      padding: 0 !important;
      width: 100% !important;
    }
  .cali__login-row{
    align-items: start !important;
    margin-top: 130px;
    .cali__login-login_container{
      .cali__login-card{
        box-shadow: none !important;
        background-color: #F6F6F6;
        font-family: 'Open-sans', sans-serif;
        align-items: start;
        .cali__login-card-header{
          background-color: #F6F6F6;
          margin-bottom: 25px !important;
          text-align: left !important;
          h5{
            text-align: left;
            font-size: 48px;
            color: #252F40;
          }
          span{
            text-align: left !important;
            color: #252F40;
          }
          label{
            font-size: 14px !important;
          }
        }
        .cali__login-card_body{
          input{
            padding: 7px 10px !important;
            width: 100%;
          }
          .cali__login-button{
            margin-block: 30px !important;
            text-transform: none !important;
            font-size: 15px !important;
            letter-spacing: 0.01em;
            font-weight: 500;
            background: rgb(0,69,97) !important;
            background: linear-gradient(315deg, rgba(0,69,97,1) 0%, rgba(2,133,166,1) 100%) !important;
            color: white;
            max-width: 160px;
            margin-left: auto;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            transition: transform 0.1s ease-in;
            .cali__login-spiner{
              width: min-content !important;
            }
            &:hover{
              background: rgb(0,69,97) !important;
              background: linear-gradient(315deg, rgba(0,69,97,1) 0%, rgba(2,133,166,1) 100%) !important;
              transform: scale(1.01);
              transition: transform 0.1s ease-in;
            }
            padding: 9px 15px !important;
            color: white;
          }
        }
      }
    }
  }
}
.oblique-image{
  transform: none !important;
}
</style>
